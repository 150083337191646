<template>
  <div class="section-2 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <v-row justify="space-between">
        <v-col cols="12">
          <div class="d-flex flex-column" style="height: 100%; justify-content: space-evenly">
            <div class="evo-section2-title text-center evo-text-58">
              {{ $t('transfer.settle_your_funds_to_overseas') }}
            </div>
            <div v-for="item in items" :key="item.key" class="d-flex evo-item-wrapper">
              <img loading="lazy" :src="item.src" class="evo-item-img" />
              <div class="d-flex flex-column justify-space-between">
                <span class="evo-item-title evo-mb-6 evo-text-42">
                  {{ $t(item.title) }}
                </span>
                <span class="evo-item-desc evo-text-24 evo-opensans-regular">
                  {{ $t(item.desc) }}
                </span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="evo-img-wrapper">
          <img loading="lazy" class="evo-funds-oversea-img" src="@/assets/transfer/funds-oversea.png" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { interWalletRemittanceServiceSection2Mixin } from '@/mixins/transfer.js';
export default {
  name: 'EvoMInterWalletRemittanceServiceSectionTwo',
  mixins: [interWalletRemittanceServiceSection2Mixin]
};
</script>

<style lang="scss" scoped>
.section-2 {
  position: relative;

  .evo-section2-title {
    color: $title_color;
    margin-bottom: 48px;
  }

  .evo-img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .evo-funds-oversea-img {
      width: 640px;
      height: auto;
    }
  }

  .evo-item-wrapper {
    align-items: flex-start;
    margin-bottom: 48px;

    .evo-item-img {
      width: 54px;
      height: 54px;
      margin-right: 24px;
    }

    .evo-item-title {
      color: $title_color;
      margin-bottom: 8px;
    }

    .evo-item-desc {
      color: #a0a3a8;
    }
  }
}
</style>
