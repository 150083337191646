import EvoEllipse from "@/components/Ellipse.vue";

export const interWalletRemittanceServiceSection2Mixin = {
    data: () => ({
        items: [
            {
                key: 1,
                src: require("@/assets/transfer/section2-1.png"),
                title: "transfer.title_section_2_1",
                desc: "transfer.desc_section_2_1",
            },
            {
                key: 2,
                src: require("@/assets/transfer/section2-2.png"),
                title: "transfer.title_section_2_2",
                desc: "transfer.desc_section_2_2",
            },
            {
                key: 3,
                src: require("@/assets/transfer/section2-3.png"),
                title: "transfer.title_section_2_3",
                desc: "transfer.desc_section_2_3",
            },
        ],
    }),
    components: {
        EvoEllipse,
    },
}

export const interWalletRemittanceServiceSection3Mixin = {
    data: () => ({
        items: [
            {
                key: 1,
                src: require("@/assets/transfer/section3-1.png"),
                title: "transfer.title_section_3_1",
                desc: "transfer.desc_section_3_1",
            },
            {
                key: 2,
                src: require("@/assets/transfer/section3-2.png"),
                title: "transfer.title_section_3_2",
                desc: "transfer.desc_section_3_2",
            },
        ],
    }),
}