<template>
  <div class="evo-transfer-wrapper">
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMInterWalletRemittanceServiceSectionOne />
      <EvoMInterWalletRemittanceServiceSectionTwo />
      <EvoMInterWalletRemittanceServiceSectionThree />
      <EvoMInterWalletRemittanceServiceSectionFive />
    </template>
    <template v-else>
      <EvoTransferSectionOne />
      <EvoTransferSectionTwo />
      <EvoTransferSectionThree />
      <EvoTransferSectionFive />
    </template>
  </div>
</template>

<script>
import EvoTransferSectionOne from '@/components/transfer_sections/Section_1.vue';
import EvoTransferSectionTwo from '@/components/transfer_sections/Section_2.vue';
import EvoTransferSectionThree from '@/components/transfer_sections/Section_3.vue';
import EvoTransferSectionFive from '@/components/transfer_sections/Section_5.vue';

// mobile imports
import EvoMInterWalletRemittanceServiceSectionOne from '@/mobile/components/transfer_sections/MSection_1.vue';
import EvoMInterWalletRemittanceServiceSectionTwo from '@/mobile/components/transfer_sections/MSection_2.vue';
import EvoMInterWalletRemittanceServiceSectionThree from '@/mobile/components/transfer_sections/MSection_3.vue';
import EvoMInterWalletRemittanceServiceSectionFive from '@/mobile/components/transfer_sections/MSection_5.vue';
export default {
  name: 'EvoTransfer',
  components: {
    EvoTransferSectionOne,
    EvoTransferSectionTwo,
    EvoTransferSectionThree,
    EvoTransferSectionFive,

    EvoMInterWalletRemittanceServiceSectionOne,
    EvoMInterWalletRemittanceServiceSectionTwo,
    EvoMInterWalletRemittanceServiceSectionThree,
    EvoMInterWalletRemittanceServiceSectionFive
  }
};
</script>

<style lang="scss" scoped>
.evo-transfer-wrapper {
  background: $header_bg_color;
}
</style>
