<template>
  <div class="section-1 section-1-bg evo-ph-206 evo-pv-60">
    <v-row>
      <v-col cols="12" sm="12" md="5">
        <div class="evo-left">
          <img
            loading="lazy"
            src="@/assets/transfer/phone.png"
            :class="{
              'evo-phone-img': !$vuetify.breakpoint.smAndDown,
              'evo-img-auto': $vuetify.breakpoint.smAndDown,
            }"
            data-aos="fade-up"
          />
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="7" class="evo-right">
        <div
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
          class="evo-section1-title evo-text-34"
        >
          {{ $t("transfer.section_1_title_1") }}
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          class="evo-section1-subtitle evo-text-15 evo-opensans-regular"
        >
          {{ $t("transfer.desc_section_1_1") }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "EvoTransferSectionOne",
};
</script>

<style lang="scss" scoped>
.section-1 {
  position: relative;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  color: #ffffff;
  background-color: $nav_color;

  .evo-left {
    position: relative;
    .evo-phone-img {
      position: absolute;
      top: -32px;
      left: -72px;
      width: 372px;
    }
  }

  .evo-right {
    padding-top: 82px;
    .evo-section1-title {
      margin-right: -30px;
      margin-bottom: 35px;
      line-height: 44px;
      white-space: pre-line;
    }

    .evo-section1-subtitle {
      line-height: 23px;
    }
  }

  .evo-transfer-img {
    width: 620px;
    bottom: -132px;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    z-index: 1;
    position: absolute;
  }
}
</style>
