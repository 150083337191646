var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-3 evo-ph-206 evo-pv-60",class:{
    'evo-pv-100': _vm.$vuetify.breakpoint.xsOnly,
    'evo-ph-80': _vm.$vuetify.breakpoint.xsOnly
  }},[_c('v-row',{staticClass:"px-0",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"5","align-self":"center"}},[_c('img',{staticClass:"evo-remitttance-records-img",class:{
          'evo-img-auto': _vm.$vuetify.breakpoint.xsOnly
        },attrs:{"loading":"lazy","src":require("@/assets/transfer/remitttance-records.png"),"data-aos":"fade-up"}})]),(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-col',{attrs:{"cols":"12","md":"1"}}):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"evo-section3-title evo-text-23",class:{ 'evo-px-text-23': _vm.$vuetify.breakpoint.xsOnly },attrs:{"data-aos":"fade-up","data-aos-delay":"100"}},[_vm._v(" "+_vm._s(_vm.$t('transfer.save_your_time_on_connecting'))+" ")]),_c('div',{staticClass:"d-flex flex-column justify-space-between",staticStyle:{"height":"100%"}},_vm._l((_vm.items),function(item){return _c('div',{key:item.key,staticClass:"d-flex evo-item-wrapper",class:{ 'evo-mb-39': item.key === 1 },style:({
            'margin-bottom': _vm.$vuetify.breakpoint.xsOnly ? '20px' : ''
          }),attrs:{"data-aos":"fade-up","data-aos-delay":"150"}},[_c('img',{staticClass:"evo-item-img",class:{ 'evo-px-item-img': _vm.$vuetify.breakpoint.xsOnly },attrs:{"loading":"lazy","src":item.src}}),_c('div',{staticClass:"d-flex flex-column justify-space-between"},[_c('span',{staticClass:"evo-item-title evo-mb-6 evo-text-16",class:{ 'evo-px-text-14': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")]),_c('span',{staticClass:"evo-item-desc evo-text-11 evo-opensans-regular",class:{ 'evo-px-text-8': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(_vm._s(_vm.$t(item.desc)))])])])}),0)])],1),_c('div',{staticClass:"evo-bg-dot-rect"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }