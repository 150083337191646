var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-2 evo-ph-206 evo-pv-60",class:{
    'evo-pv-100': _vm.$vuetify.breakpoint.xsOnly,
    'evo-ph-80': _vm.$vuetify.breakpoint.xsOnly
  }},[_c('v-row',{staticClass:"px-0",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%","justify-content":"space-evenly"}},[_c('div',{staticClass:"evo-section2-title evo-text-23",class:{ 'evo-px-text-23': _vm.$vuetify.breakpoint.xsOnly },attrs:{"data-aos":"fade-up"}},[_vm._v(" "+_vm._s(_vm.$t('transfer.settle_your_funds_to_overseas'))+" ")]),_vm._l((_vm.items),function(item){return _c('div',{key:item.key,staticClass:"d-flex evo-item-wrapper",style:({
            'margin-bottom': _vm.$vuetify.breakpoint.xsOnly ? '20px' : ''
          }),attrs:{"data-aos":"fade-up","data-aos-delay":"100"}},[_c('img',{staticClass:"evo-item-img",class:{ 'evo-px-item-img': _vm.$vuetify.breakpoint.xsOnly },attrs:{"loading":"lazy","src":item.src}}),_c('div',{staticClass:"d-flex flex-column justify-space-between"},[_c('span',{staticClass:"evo-item-title evo-mb-6 evo-text-16",class:{ 'evo-px-text-14': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")]),_c('span',{staticClass:"evo-item-desc evo-text-11 evo-opensans-regular",class:{ 'evo-px-text-8': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(_vm._s(_vm.$t(item.desc)))])])])})],2)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('img',{staticClass:"evo-funds-oversea-img",class:{
          'evo-img-auto': _vm.$vuetify.breakpoint.xsOnly
        },attrs:{"loading":"lazy","src":require("@/assets/transfer/funds-oversea.png"),"data-aos":"fade-up","data-aos-delay":"150"}})])],1),_c('EvoEllipse',{staticClass:"evo-section2-ellipse",attrs:{"width":"82px","height":"171px"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }