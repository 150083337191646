<template>
  <div
    class="section-2 evo-ph-206 evo-pv-60"
    :class="{
      'evo-pv-100': $vuetify.breakpoint.xsOnly,
      'evo-ph-80': $vuetify.breakpoint.xsOnly
    }"
  >
    <v-row class="px-0" justify="space-between">
      <v-col cols="12" sm="12" md="6">
        <div class="d-flex flex-column" style="height: 100%; justify-content: space-evenly">
          <div class="evo-section2-title evo-text-23" :class="{ 'evo-px-text-23': $vuetify.breakpoint.xsOnly }" data-aos="fade-up">
            {{ $t('transfer.settle_your_funds_to_overseas') }}
          </div>
          <div
            v-for="item in items"
            :key="item.key"
            class="d-flex evo-item-wrapper"
            :style="{
              'margin-bottom': $vuetify.breakpoint.xsOnly ? '20px' : ''
            }"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img loading="lazy" :src="item.src" class="evo-item-img" :class="{ 'evo-px-item-img': $vuetify.breakpoint.xsOnly }" />
            <div class="d-flex flex-column justify-space-between">
              <span class="evo-item-title evo-mb-6 evo-text-16" :class="{ 'evo-px-text-14': $vuetify.breakpoint.xsOnly }">
                {{ $t(item.title) }}
              </span>
              <span class="evo-item-desc evo-text-11 evo-opensans-regular" :class="{ 'evo-px-text-8': $vuetify.breakpoint.xsOnly }">{{
                $t(item.desc)
              }}</span>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <img
          loading="lazy"
          class="evo-funds-oversea-img"
          src="@/assets/transfer/funds-oversea.png"
          :class="{
            'evo-img-auto': $vuetify.breakpoint.xsOnly
          }"
          data-aos="fade-up"
          data-aos-delay="150"
        />
      </v-col>
    </v-row>

    <EvoEllipse class="evo-section2-ellipse" width="82px" height="171px" />
  </div>
</template>

<script>
import { interWalletRemittanceServiceSection2Mixin } from '@/mixins/transfer.js';
export default {
  name: 'EvoTransferSectionTwo',
  mixins: [interWalletRemittanceServiceSection2Mixin]
};
</script>

<style lang="scss" scoped>
.section-2 {
  position: relative;
  padding-top: 100px;

  .evo-section2-title {
    font-weight: bold;
    color: $title_color;
    margin-bottom: 37px;
  }

  .evo-funds-oversea-img {
    width: 382px;
    height: auto;
  }

  .evo-item-wrapper {
    .evo-item-img {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    .evo-px-item-img {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    .evo-item-title {
      font-weight: bold;
      color: $title_color;
    }

    .evo-item-desc {
      color: #a0a3a8;
    }
  }

  .evo-section2-rect1 {
    position: absolute;
    width: 79px;
    height: 47px;
    right: 0;
    bottom: 230px;
  }

  .evo-section2-rect2 {
    position: absolute;
    width: 56px;
    height: 29px;
    right: 0;
    bottom: 200px;
  }

  .evo-section2-ellipse {
    position: absolute;
    left: -20px;
    bottom: 220px;
  }
}
</style>
